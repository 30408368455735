import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Img,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";

import { openCart } from "../../../redux/actions";
import { debounce, capitalizeFirstLetter } from "../../../utils/functions";
import { trackSearchQuery } from "./../../../utils/analytics";

import CartIcon from "../../../assets/svg/cart";
import MenuIcon from "../../../assets/svg/menu";
import Drawer from "./drawer";

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderWidth: 0,
    borderRadius: 0,
    borderColor: "#8F1838",
    backgroundColor: "white",
    borderBottomWidth: isFocused ? 2 : 1,
    boxShadow: isFocused ? "#8F1838" : "",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (styles) => ({ ...styles, fontSize: 14 }),
  options: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
};

const Header = ({ cart, searchableProducts, productCategories }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [primaryOpen, setPrimaryOpen] = useState(false);
  const [secondaryOpen, setSecondaryOpen] = useState(null);
  const [searchString, setSearchString] = useState(null);

  const handleSearchSelect = async (selectedOption) => {
    router.push({ pathname: `/product/${selectedOption.value}` });
  };

  const debouncedTrackSearch = debounce((searchTerm) => {
    trackSearchQuery(searchTerm);
  }, 300);

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      trackSearchQuery(e.target.value);
      router.push({ pathname: "/search", query: { s: e.target.value } });
    } else {
      debouncedTrackSearch(e.target.value);
      setSearchString(e.target.value);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setPrimaryOpen(false);
    setSecondaryOpen(null);
  };

  const openMainMenu = () => {
    setPrimaryOpen(false);
    setSecondaryOpen(null);
  };
  const openProductPrimaryMenu = () => {
    setPrimaryOpen(true);
    setSecondaryOpen(null);
  };
  const openProductSecondaryMenu = (cat) => {
    setPrimaryOpen(false);
    setSecondaryOpen(cat.id);
  };

  const midSizedScreenHeader = (
    <Flex
      top={0}
      h="70px"
      bg="white"
      width="90%"
      zIndex={1000}
      align="center"
      justifyContent="space-between"
      d={{ base: "none", sm: "flex", xl: "none" }}
    >
      <Img
        h="45px"
        _hover={{ cursor: "pointer" }}
        fallback={<Skeleton w="100%" />}
        onClick={() => router.push("/")}
        src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/logo_header.png`}
      />
      <Flex>
        <Box mr={1} w="100%" width={"200px"}>
          <ReactSelect
            placeholder="Search by product name"
            value={searchString}
            styles={colourStyles}
            onKeyDown={handleSearch}
            onChange={handleSearchSelect}
            onFocus={() => setSearchString(null)}
            options={searchableProducts.map((p) => ({
              value: p.slug,
              label: p.name
                .split(" ")
                .map((w) => ` ${capitalizeFirstLetter(w)}`),
            }))}
          />
        </Box>
        <Avatar bg="#fff" style={{ marginRight: "15px" }}>
          <Flex>
            <CartIcon
              width="24"
              height="24"
              color="maroon"
              onClick={() => dispatch(openCart())}
              style={{ marginRight: "10px" }}
            />
            {cart && cart.length > 0 && (
              <Box ml="7px">
                <AvatarBadge bg="#8f1838" boxSize="1em">
                  <Text
                    variant="aboutUsText"
                    fontWeight="bold"
                    fontSize="10px"
                    color={"#fff"}
                  >
                    {cart.length}
                  </Text>
                </AvatarBadge>
              </Box>
            )}
          </Flex>
        </Avatar>
        <MenuIcon
          color="maroon"
          height="24"
          width="24"
          onClick={() => setDrawerOpen(true)}
          style={{ marginTop: "10px" }}
        />
      </Flex>
    </Flex>
  );

  const smallScreenHeader = (
    <Flex
      width="100%"
      align="center"
      flexDirection={"column"}
      d={{ base: "flex", sm: "none" }}
    >
      <Flex
        top={0}
        h="70px"
        bg="white"
        width="90%"
        zIndex={1000}
        align="center"
        justifyContent="space-between"
      >
        <Img
          h="45px"
          _hover={{ cursor: "pointer" }}
          fallback={<Skeleton w="100%" />}
          onClick={() => router.push("/")}
          src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/logo_header.png`}
        />
        <Flex>
          <Avatar bg="#fff" style={{ marginRight: "15px" }}>
            <Flex>
              <CartIcon
                width="24"
                height="24"
                color="maroon"
                onClick={() => dispatch(openCart())}
                style={{ marginRight: "10px" }}
              />
              {cart && cart.length > 0 && (
                <Box ml="7px">
                  <AvatarBadge bg="#8f1838" boxSize="1em">
                    <Text
                      variant="aboutUsText"
                      fontWeight="bold"
                      fontSize="10px"
                      color={"#fff"}
                    >
                      {cart.length}
                    </Text>
                  </AvatarBadge>
                </Box>
              )}
            </Flex>
          </Avatar>
          <MenuIcon
            color="maroon"
            height="24"
            width="24"
            onClick={() => setDrawerOpen(true)}
            style={{ marginTop: "10px" }}
          />
        </Flex>
      </Flex>
      <Box mt="1" mb="3" w="70%">
        <ReactSelect
          placeholder="Search by product name"
          value={searchString}
          styles={colourStyles}
          onKeyDown={handleSearch}
          options={searchableProducts.map((p) => ({
            value: p.slug,
            label: p.name.split(" ").map((w) => ` ${capitalizeFirstLetter(w)}`),
          }))}
          onFocus={() => setSearchString(null)}
          onChange={handleSearchSelect}
        />
      </Box>
    </Flex>
  );

  return (
    <>
      <Drawer
        drawerOpen={drawerOpen}
        primaryOpen={primaryOpen}
        openMainMenu={openMainMenu}
        secondaryOpen={secondaryOpen}
        closeDrawer={handleCloseDrawer}
        productCategories={productCategories}
        openProductPrimaryMenu={openProductPrimaryMenu}
        openProductSecondaryMenu={openProductSecondaryMenu}
      />

      {midSizedScreenHeader}
      {smallScreenHeader}
    </>
  );
};
export default Header;
